// // Colors
// $white: #ffffff;
// $light: #f3f4f7;
// $dark: #11263c;
// $grey: #a1a9b3;
// $primary: #6f52ed;
// $success: #10ac84;
// $danger: #f42c37;
// $warning: #f5c84c;
// $sourcedBlue: #007bff;
// $lightGrey: #858585;
// $purple: #6f52ed;

// // Font Family
// @font-face {
//   font-family: "Ginger";
//   src: url("/fonts/f37ginger-regular-webfont.eot");
//   src: url("/fonts/f37ginger-regular-webfont.eot?#iefix")
//       format("embedded-opentype"),
//     url("/fonts/f37ginger-regular-webfont.woff") format("woff"),
//     url("/fonts/f37ginger-regular-webfont.ttf") format("truetype");
//   font-weight: normal;
//   font-style: normal;
// }

// @font-face {
//   font-family: "Ginger";
//   src: url("/fonts/f37ginger-bold-webfont.eot");
//   src: url("/fonts/f37ginger-bold-webfont.eot?#iefix")
//       format("embedded-opentype"),
//     url("/fonts/f37ginger-bold-webfont.woff") format("woff"),
//     url("/fonts/f37ginger-bold-webfont.ttf") format("truetype");
//   font-weight: bold;
//   font-style: normal;
// }
@font-face {
  font-family: ProximaNovaBold;
  src: url("./Fonts/Proxima\ Nova\ Alt\ Bold.otf");
}
@font-face {
  font-family: ProximaNovaExtraBold;
  src: url("./Fonts/Proxima\ Nova\ Extrabold.otf");
}
@font-face {
  font-family: ProximaNovaThin;
  src: url("./Fonts/Proxima\ Nova\ Alt\ Thin.otf");
}
@font-face {
  font-family: ProximaNovaRegular;
  src: url("./Fonts/ProximaNova-Regular.otf");
}
@font-face {
  font-family: ProximaNovaLight;
  src: url("./Fonts/Proxima\ Nova\ Alt\ Light.otf");
}

// // Font Style
// $ginger: "Ginger";

// html {
//   font-size: initial;
// }

body {
  background-color: #f7f8fa;
  overflow-x: hidden;
}

// a {
//   color: $primary;
//   text-decoration: none;
//   transition: 0.25s;

//   &:hover {
//     text-decoration: none;
//     color: #ee405e !important;
//   }
// }

// .btn {
//   border-radius: 7px;
//   font-size: 1rem;
//   padding: 0.5em 1rem;
//   transition: 0.25s;
// }

// .btn-dark {
//   background-color: $dark !important;
//   border: 0 !important;
//   box-shadow: 0px 10px 20px #11263c40 !important;

//   &:not(:disabled) {
//     &:hover {
//       background-color: darken($color: $dark, $amount: 7.5%) !important;
//     }
//   }
// }

// .btn-primary {
//   background-color: $primary !important;
//   border: 0 !important;
//   box-shadow: 0px 10px 20px #6f52ed40 !important;

//   &:not(:disabled) {
//     &:hover {
//       background-color: darken($color: $primary, $amount: 7.5%) !important;
//     }
//   }
// }

// .btn-success {
//   background-color: #8bf087 !important;
//   border: 0 !important;
//   box-shadow: 0px 10px 20px #8bf08740;

//   &:not(:disabled) {
//     &:hover {
//       background-color: darken($color: $success, $amount: 7.5%) !important;
//     }
//   }
// }

// .btn-danger {
//   background-color: $danger !important;
//   border: 0 !important;
//   box-shadow: 0px 10px 20px #f2576740 !important;

//   &:not(:disabled) {
//     &:hover {
//       background-color: darken($color: $danger, $amount: 7.5%) !important;
//     }
//   }
// }

// .btn-warning {
//   background-color: $warning;
//   border: 0 !important;
//   box-shadow: 0px 10px 20px #ffa00040;

//   &:not(:disabled) {
//     &:hover {
//       background-color: darken($color: $warning, $amount: 7.5%) !important;
//     }
//   }
// }

// .bg-light {
//   background-color: $light;
// }

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 0px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #bd8022;
}

// .custom-control-input:checked ~ .custom-control-label::before {
//   color: #fff !important;
//   border: 2px solid #f5c84c !important;
//   background-color: #f5c84c !important;
//   box-shadow: 1px 1px 5px #f5c84c !important;
// }

// .custom-control-label {
//   text-align: left;
//   font-size: 15px;
//   letter-spacing: 0.75px;
//   color: #979797;
//   opacity: 1;
// }

@media only screen and (min-width: 768px) {
  // FOR DESKTOP AND TABLET
  .mainContainer {
    width: calc(100% - 250px);
    min-height: 100vh;
    margin-left: 250px;
    margin-top: 60px;
    text-align: left;
    background-color: #f8faff;
    padding: 15px 20px;
    .alert {
      margin: 10px 0px;
    }
    // title
    .title {
      font: normal normal bold 28px/34px ProximaNovaBold;
      margin-left: 10px;
      margin-bottom: 15px;
      color: #000000;
      text-align: left;
      letter-spacing: 0px;
      opacity: 1;
    }
    .sectionCard {
      background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
      background: #ffffff 0% 0% no-repeat padding-box;
      border-radius: 10px;
      opacity: 1;
    }
  }
}
@media only screen and (max-width: 767px) {
  .mainContainer {
    margin-top: 80px;
    // title
    .title {
      font: normal normal bold 24px/30px ProximaNovaBold;
      margin-left: 10px;
      margin-bottom: 0px;
    }
  }
}
