@media only screen and (min-width: 768px) {
  // FOR DESKTOP AND TABLET
  .dashboard_container {
    width: 100%;
    .dashboard_content_container {
      width: 80%;
      height: 100%;
      margin-left: 250px;
      padding: 15px 15px 15px 30px;
      h2 {
        text-align: left;
        font-family: sans-serif;
        font-weight: 600;
        letter-spacing: 1.65px;
        color: #707070;
        opacity: 1;
        margin: 10px 0px;
      }

      hr {
        margin: 8px 0px;
      }
      .table_card {
        width: 100%;
        height: 100%;
        margin-top: 25px;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #e7eaf0;
        border-radius: 5px;
        opacity: 1;

        .table_card_body {
          .Div1 {
            display: flex;

            .Section1 {
              .Section1_part1 {
                display: flex;
                margin-top: 50px;
                margin-bottom: 20px;

                p {
                  width: 130px;
                  font-weight: 500;
                }
                input {
                  padding-left: 10px;
                  width: 360px;
                  height: 28px;
                  border: none;
                  border-radius: 3px;
                  background-color: #f2f4f5;
                }
                input::placeholder {
                  color: #bbbcbd;
                }
              }

              .Section1_part2 {
                display: flex;
                margin-top: -10px;

                p {
                  width: 130px;
                  font-weight: 500;
                }
                input {
                  padding-left: 10px;
                  width: 360px;
                  height: 28px;
                  border: none;
                  border-radius: 3px;
                  background-color: #f2f4f5;
                }
                input::placeholder {
                  color: #bbbcbd;
                }
              }
              .Section1_part3 {
                display: flex;
                margin-top: 80px;
                margin-bottom: 20px;

                p {
                  width: 130px;
                  font-weight: 500;
                }
                input {
                  padding-left: 10px;
                  width: 360px;
                  height: 28px;
                  border: none;
                  border-radius: 3px;
                  background-color: #f2f4f5;
                }
                input::placeholder {
                  color: #bbbcbd;
                }
              }
              .Section1_part4 {
                display: flex;
                margin-top: -10px;

                p {
                  width: 130px;
                  font-weight: 500;
                }
                input {
                  padding-left: 10px;
                  width: 360px;
                  height: 28px;
                  border: none;
                  border-radius: 3px;
                  background-color: #f2f4f5;
                }
                input::placeholder {
                  color: #bbbcbd;
                }
              }
            }
            .Section2 {
              display: block;
              text-align: center;
              margin-left: 40px;
              margin-top: 40px;

              .Section2_part1 {
                img {
                  width: 160px;
                }
              }
              .Section2_part2 {
                margin-top: 10px;
                button {
                  width: 120px;
                  height: 30px;
                  border: none;
                  border-radius: 5px;
                  background-color: #df474b;
                  color: white;
                }
              }
              .Section2_part3 {
                margin-top: 20px;
                img {
                  width: 160px;
                }
              }
              .Section2_part4 {
                margin-top: 10px;
                button {
                  width: 120px;
                  height: 30px;
                  border: none;
                  border-radius: 5px;
                  background-color: #df474b;
                  color: white;
                }
              }
            }
          }
        }
        .Div2 {
          display: flex;
          margin-top: 40px;

          .Section1 {
            .Section1_part1 {
              display: flex;
              p {
                width: 130px;
                font-weight: 500;
              }
              input {
                padding-left: 10px;
                width: 340px;
                height: 28px;
                border: none;
                border-radius: 3px;
                background-color: #f2f4f5;
              }
              input::placeholder {
                color: #bbbcbd;
              }
            }
            .Section1_part2 {
              display: flex;
              margin-top: 7px;
              p {
                width: 130px;
                font-weight: 500;
              }
              input {
                padding-left: 10px;
                width: 340px;
                height: 28px;
                border: none;
                border-radius: 3px;
                background-color: #f2f4f5;
              }
              input::placeholder {
                color: #bbbcbd;
              }
            }
          }
          .Section2 {
            display: flex;
            margin-left: 10px;
            margin-top: 35px;

            p {
              font-weight: 500;
            }
            input {
              margin-left: 7px;
              padding-left: 10px;
              width: 147px;
              height: 28px;
              border: none;
              border-radius: 3px;
              background-color: #f2f4f5;
            }
            input::placeholder {
              color: #bbbcbd;
            }
          }
        }
      }
      .Div3 {
        display: flex;
        margin-top: 35px;
        margin-left: 30px;

        .Section1 {
          text-align: center;
          .Section1_part1 {
            img {
              width: 160px;
            }
          }
          .Section1_part2 {
            margin-top: 10px;
            button {
              width: 120px;
              height: 30px;
              border: none;
              border-radius: 5px;
              background-color: #df474b;
              color: white;
            }
          }
        }
        .Section2 {
          text-align: center;
          margin-left: 20px;
          .Section2_part1 {
            img {
              width: 160px;
            }
          }
          .Section2_part2 {
            margin-top: 10px;
            button {
              width: 120px;
              height: 30px;
              border: none;
              border-radius: 5px;
              background-color: #df474b;
              color: white;
            }
          }
        }
      }

      .Div4 {
        display: flex;
        margin-top: 50px;

        .Section1 {
          display: flex;
          margin-top: 45px;
          p {
            width: 80px;
            font-weight: 500;
          }
          textarea {
            padding-left: 10px;
            width: 240px;
            height: 28px;
            border: none;
            border-radius: 3px;
            background-color: #f2f4f5;
          }
          textarea::placeholder {
            color: #bbbcbd;
          }
        }

        .Section2 {
          display: flex;
          margin-left: 10px;
          .Section2_part1 {
            display: block;
            text-align: center;
            margin-left: 4px;

            .Section2_part1 {
              img {
                width: 160px;
              }
            }
            .Section2_part2 {
              margin-top: 10px;
              button {
                width: 120px;
                height: 30px;
                border: none;
                border-radius: 5px;
                background-color: #df474b;
                color: white;
              }
            }
          }
        }
      }

      .Div5 {
        display: flex;
        margin-top: 50px;

        .Section1 {
          display: flex;
          p {
            font-weight: 500;
          }
          input {
            margin-left: 10px;
            padding-left: 10px;
            width: 380px;
            height: 28px;
            border: none;
            border-radius: 3px;
            background-color: #f2f4f5;
          }
          input::placeholder {
            color: #bbbcbd;
          }
        }
        .Section2 {
          display: flex;
          margin-left: 20px;
          p {
            font-weight: 500;
          }
          textarea {
            margin-left: 7px;
            padding-left: 10px;
            width: 440px;
            height: 28px;
            border: none;
            border-radius: 3px;
            background-color: #f2f4f5;
          }
          textarea::placeholder {
            color: #bbbcbd;
          }
        }
      }

      .Div6 {
        display: flex;
        margin-top: 50px;

        .Section1 {
          .Section1_part1 {
            display: flex;
            margin-top: 7px;

            p {
              font-weight: 500;
              width: 130px;
            }
            input {
              margin-left: 10px;
              padding-left: 10px;
              width: 380px;
              height: 28px;
              border: none;
              border-radius: 3px;
              background-color: #f2f4f5;
            }
            input::placeholder {
              color: #bbbcbd;
            }
          }

          .Section1_part2 {
            display: flex;
            margin-top: 30px;

            p {
              font-weight: 500;
              width: 130px;
            }
            input {
              margin-left: 10px;
              padding-left: 10px;
              width: 380px;
              height: 28px;
              border: none;
              border-radius: 3px;
              background-color: #f2f4f5;
            }
            input::placeholder {
              color: #bbbcbd;
            }
          }

          .Section1_part3 {
            display: flex;
            margin-top: 30px;

            p {
              font-weight: 500;
              width: 130px;
            }
            input {
              margin-left: 10px;
              padding-left: 10px;
              width: 380px;
              height: 28px;
              border: none;
              border-radius: 3px;
              background-color: #f2f4f5;
            }
            input::placeholder {
              color: #bbbcbd;
            }
          }

          .Section1_part {
            display: flex;
            margin-top: 7px;

            p {
              font-weight: 500;
              width: 130px;
            }
            input {
              margin-left: 10px;
              padding-left: 10px;
              width: 380px;
              height: 28px;
              border: none;
              border-radius: 3px;
              background-color: #f2f4f5;
            }
            input::placeholder {
              color: #bbbcbd;
            }
          }
        }

        .Section2 {
          display: block;
          margin-left: 50px;

          .Section2_part1 {
            text-align: center;
            img {
              width: 160px;
            }

            button {
              margin-top: 10px;
              width: 120px;
              height: 30px;
              border: none;
              border-radius: 5px;
              background-color: #df474b;
              color: white;
            }
          }

          .Section2_part2 {
            text-align: center;
            margin-top: 20px;
            img {
              width: 160px;
            }

            button {
              margin-top: 10px;
              width: 120px;
              height: 30px;
              border: none;
              border-radius: 5px;
              background-color: #df474b;
              color: white;
            }
          }
        }
      }

      .bottomButton {
        margin: 0 300px;
        margin-top: 100px;
        margin-bottom: 30px;
        button {
          width: 250px;
          height: 50px;
          font-size: 20px;
          border: none;
          border-radius: 5px;
          background-color: #df474b;
          color: white;
        }
      }
    }
  }
}

//   .table_header_custom {
//     background: #f8faff 0% 0% no-repeat padding-box;
//     border-radius: 5px;
//     opacity: 1;
//     padding: 10px 0px 4px 0px;

// h4 {
//   width: 100% !important;
//   text-align: center;
//   background: #f8faff 0% 0% no-repeat padding-box;
//   position: sticky;
//   top: 0;
//   font-family: Goldplay;
//   font-size: 1.3vw;
//   font-weight: 600;
//   letter-spacing: 0.75px;
//   color: #707070;
//   opacity: 1;
//   border-top: none !important;
//   border-bottom: none !important;
//   z-index: 984;
// }
//   }
