.btn {
    border: none;
    text-align: left;
    // font: normal normal normal 12px/20px ProximaNovaBold;
    font-size: 12px/20px;
    letter-spacing: 0px;
    color: #FFFFFF;
    padding: 5px 10px;
    border-radius: 7px;
    a {
        text-decoration: none;
        color: #FFFFFF;
    }
}
@media screen and (max-width: 768px) {
    .btn {
        border: none;
        text-align: left;
        // font: normal normal normal 12px/20px ProximaNovaBold;
        font-size: 8px/16px;
        letter-spacing: 0px;
        color: #FFFFFF;
        padding: 5px 7px;
        border-radius: 7px;
    }
}