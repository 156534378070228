@media only screen and (min-width: 768px) {
  .container {
    padding: 30px 30px;

    .addContainer {
      margin-bottom: 30px;
    }

    .fleetsContainer {
      .fleet {
        display: flex;
        margin: 30px 0px;
        .leftSection {
          width: 40%;
          .field {
            margin: 7px 0px;
            display: flex;
            label {
              width: 25%;
              text-align: left;
              font: normal normal normal 16px/20px ProximaNovaBold;
              letter-spacing: 0px;
              color: #343434;
              opacity: 1;
              margin-top: 5px;
            }
            input {
              width: 75%;
              background: #f2f4f5 0% 0% no-repeat padding-box;
              border-radius: 4px;
              opacity: 1;
              border: none;
              outline: none;
              text-align: left;
              font: normal normal normal 14px/1px ProximaNovaBold;
              letter-spacing: 0px;
              color: #707070;
              padding: 10px 14px;
            }
          }
        }
        .middleSection {
          width: 15%;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 20px;
          img {
            height: 60px;
            width: 125px;
          }
          .hideInput {
            opacity: 0;
            margin: -28px -200px 0px 0px;
          }
          ._files {
            font-size: 12px;
            margin-top: 5px;
          }
        }
        .rightSection {
          width: 40%;
          .field {
            margin: 7px 0px;
            display: flex;
            label {
              width: 20%;
              text-align: left;
              font: normal normal normal 16px/20px ProximaNovaBold;
              letter-spacing: 0px;
              color: #343434;
              opacity: 1;
              margin-top: 5px;
            }
            input {
              width: 80%;
              background: #f2f4f5 0% 0% no-repeat padding-box;
              border-radius: 4px;
              opacity: 1;
              border: none;
              outline: none;
              text-align: left;
              font: normal normal normal 14px/1px ProximaNovaBold;
              letter-spacing: 0px;
              color: #707070;
              padding: 10px 14px;
            }
          }
        }
        .btnSection {
          width: 10%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          margin-top: 25px;
          // icons
          img:nth-child(2) {
          }
          img {
            cursor: pointer;
            width: 20px;
          }
        }
      }
    }
  }
}

// for mobile devices

@media only screen and (max-width: 767px) {
  .container {
    padding: 30px 20px;
    .addContainer {
      margin-bottom: 0px;
    }

    .fleetsContainer {
      .fleet {
        display: flex;
        flex-direction: column;
        margin: 50px 0px;
        .leftSection {
          width: 100%;
          order: 2;
          margin: 10px 0px;
          .field {
            margin: 7px 0px;
            display: flex;
            flex-direction: column;
            label {
              width: 100%;
              text-align: left;
              font: normal normal normal 16px/20px ProximaNovaBold;
              letter-spacing: 0px;
              color: #343434;
              opacity: 1;
              margin-bottom: 10px;
            }
            input {
              width: 100%;
              max-width: 500px;
              background: #f2f4f5 0% 0% no-repeat padding-box;
              border-radius: 4px;
              opacity: 1;
              border: none;
              outline: none;
              text-align: left;
              font: normal normal normal 14px/1px ProximaNovaBold;
              letter-spacing: 0px;
              color: #707070;
              padding: 15px 14px;
              margin-bottom: 10px;
            }
          }
        }
        .middleSection {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          order: 3;
          margin: 10px 0px;
          overflow: hidden;
          img {
            width: 125px;
          }
          .hideInput {
            opacity: 0;
            margin: -28px -200px 0px 0px;
          }
        }
        .rightSection {
          width: 100%;
          order: 4;
          margin: 10px 0px;
          .field {
            margin: 7px 0px;
            display: flex;
            flex-direction: column;
            label {
              width: 100%;
              text-align: left;
              font: normal normal normal 16px/20px ProximaNovaBold;
              letter-spacing: 0px;
              color: #343434;
              opacity: 1;
              margin-bottom: 10px;
            }
            input {
              width: 100%;
              background: #f2f4f5 0% 0% no-repeat padding-box;
              border-radius: 4px;
              opacity: 1;
              border: none;
              outline: none;
              text-align: left;
              font: normal normal normal 14px/1px ProximaNovaBold;
              letter-spacing: 0px;
              color: #707070;
              margin-bottom: 10px;
              padding: 15px 14px;
            }
          }
        }
        .btnSection {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          margin: 10px 0px;
          order: 1;
          // icons
          img:nth-child(2) {
            margin-left: 14px;
          }
          img {
            cursor: pointer;
            width: 15px;
          }
        }
      }
    }
  }
}
