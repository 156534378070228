@media only screen and (min-width: 768px) {
    .container {
        padding: 30px 30px;

        .addContainer {
            margin-bottom: 30px;
        }

        .fleetsContainer {
            .fleet {
                display: flex;
                margin: 30px 0px;
                .leftSection {
                    width: 85%;
                    .field {
                        margin: 12px 0px;
                        display: flex;
                        label {
                            width: 10%;
                            text-align: left;
                            font: normal normal normal 16px/20px ProximaNovaBold;
                            letter-spacing: 0px;
                            color: #343434;
                            opacity: 1;
                            margin-top: 8px;
                        }
                        input {
                            width: 90%;
                            background: #F2F4F5 0% 0% no-repeat padding-box;
                            border-radius: 4px;
                            opacity: 1;
                            border: none;
                            outline: none;
                            text-align: left;
                            font: normal normal normal 14px/1px ProximaNovaBold;
                            letter-spacing: 0px;
                            color: #707070;
                            padding: 12px 14px;
                        }
                }}
                .btnSection {
                    width: 5%;
                    display: flex;
                    justify-content: flex-end;
                    align-items: flex-start;
                    margin-top: 5px;
                    // icons
                    img:nth-child(2) {
                        margin-left: 14px;
                    }
                    img {
                        cursor: pointer;
                        width: 20px;
                    }
                }
            }
                .btnContainer {
                    display: flex;
                    justify-content: center;
                }
        }
    }
}

// for mobile devices

@media only screen and (max-width: 767px) {
    .container {
        padding: 30px 15px;

        .addContainer {
            margin-bottom: 30px;
        }

        .fleetsContainer {
            .fleet {
                display: flex;
                margin: 30px 0px;
                .leftSection {
                    width: 90%;
                    .field {
                        margin: 12px 0px;
                        display: flex;
                        flex-direction: column;
                        label {
                            width: 100%;
                            text-align: left;
                            font: normal normal normal 16px/20px ProximaNovaBold;
                            letter-spacing: 0px;
                            color: #343434;
                            opacity: 1;
                            margin-bottom: 10px;
                        }
                        input {
                            width: 100%;
                            background: #F2F4F5 0% 0% no-repeat padding-box;
                            border-radius: 4px;
                            opacity: 1;
                            border: none;
                            outline: none;
                            text-align: left;
                            font: normal normal normal 14px/1px ProximaNovaBold;
                            letter-spacing: 0px;
                            color: #707070;
                            padding: 16px 14px;
                        }
                }}
                .btnSection {
                    width: 10%;
                    display: flex;
                    justify-content: flex-end;
                    align-items: flex-start;
                    margin-top: 5px;
                    // icons
                    img:nth-child(2) {
                        margin-left: 14px;
                    }
                    img {
                        cursor: pointer;
                        width: 15px;
                    }
                }
            }
                .btnContainer {
                    display: flex;
                    justify-content: center;
                }
        }
    }
}