.loader {
  height: 80vh;
  text-align: center;
  margin-top: 130px;

  img {
    width: 170px;
  }

  .spinner {
    height: 70px;
    width: 70px;
    color: #e73f5e;
  }
  
}
