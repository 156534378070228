.fieldsContainer {
    .field {
    margin: 12px 0px;
    display: flex;
    align-items: center;
    label {
        width: 30%;
        text-align: left;
        font: normal normal normal 16px/20px ProximaNovaBold;
        letter-spacing: 0px;
        color: #343434;
        opacity: 1;
    }
    input {
        width: 70%;
        background: #F2F4F5 0% 0% no-repeat padding-box;
        border-radius: 4px;
        opacity: 1;
        border: none;
        outline: none;
        text-align: left;
        font: normal normal normal 14px/1px ProximaNovaBold;
        letter-spacing: 0px;
        color: #707070;
        padding: 12px 14px;
    }
    }

    .btnContainer {
        display: flex;
            flex-direction: row;
            align-items: center;
            label {
                width: 30%;
                text-align: left;
                font: normal normal normal 14px/18px ProximaNovaBold;
                letter-spacing: 0px;
                color: #343434;
                opacity: 1;
            }
            .btnWrapper {
                width: 62%;
                width: 50%;
            }
            .btnWrapper2 {
                width: 2%;
                width: 2%;
            }
            
            .hideInput {
                position: absolute;
            opacity: 0;
            // margin: -10px 0px 0px -325px;
            border: 1px solid black;
            left: 155px;
            }
            ._files {
            font-size: 12px;
            }
}
}

@media screen and (max-width: 767px) {
    .fieldsContainer {
        .field {
        margin: 12px 0px;
        display: flex;
        align-items: center;
        label {
            width: 30%;
            text-align: left;
            font: normal normal normal 14px/18px ProximaNovaBold;
            letter-spacing: 0px;
            color: #343434;
            opacity: 1;
        }
        input {
            width: 70%;
            background: #F2F4F5 0% 0% no-repeat padding-box;
            border-radius: 4px;
            opacity: 1;
            border: none;
            outline: none;
            text-align: left;
            font: normal normal normal 12px/1px ProximaNovaBold;
            letter-spacing: 0px;
            color: #707070;
            padding: 12px 14px;
        }
        }
        .btnContainer {
            display: flex;
            flex-direction: row;
            align-items: center;
            label {
                width: 30%;
                text-align: left;
                font: normal normal normal 14px/18px ProximaNovaBold;
                letter-spacing: 0px;
                color: #343434;
                opacity: 1;
            }
            .btnWrapper {
                width: 70%;
            }
            .hideInput {
                position: absolute;
                opacity: 0;
                // margin: -28px -200px 0px 0px;
                left: 115px;
            }
            ._files {
                font-size: 12px;
            }
    }
    }
}