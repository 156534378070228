@media screen and (min-width: 768px) {
  .subSection {
    display: flex;
    justify-content: space-between;
    .leftSection {
      width: 100%;
      display: flex;
      flex-direction: column;
      .container {
        padding: 20px 30px;
        margin-bottom: 10px;
        background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
        background: #ffffff 0% 0% no-repeat padding-box;
        border-radius: 10px;
        opacity: 1;
        display: flex;
        flex-direction: column;
        header {
          width: 100%;
          display: flex;
          align-items: center;
          padding: 5px 0px;
          margin-bottom: 10px;
          h4 {
            margin-right: 10px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .subSection {
    display: flex;
    justify-content: space-between;
    .leftSection {
      width: 100%;
      display: flex;
      flex-direction: column;
      .container {
        padding: 20px 30px;
        margin-bottom: 10px;
        background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
        background: #ffffff 0% 0% no-repeat padding-box;
        border-radius: 10px;
        opacity: 1;
        display: flex;
        flex-direction: column;
        header {
          width: 100%;
          display: flex;
          align-items: center;
          padding: 5px 0px;
          margin-bottom: 10px;
          h4 {
            margin-right: 10px;
          }
        }
      }
    }
  }
}
