@media only screen and (min-width: 768px) {
  .mainContainer {
    position: fixed;
    top: 0;
    width: 250px;
    height: 100vh;
    background: #2d323e 0% 0% no-repeat;
    overflow-y: auto;
    overflow-x: hidden;
    opacity: 1;
    // padding: 20px;
    .logoContainer {
      margin-top: 10px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 100px;
      }
      i {
        display: none;
      }
    }
    .menuContainer {
      padding-top: 20px;
      .linksContainer {
        font-family: ProximaNovaRegular;
        text-decoration: none;
        display: flex;
        align-items: center;
        // height: 40px;
        padding: 10px 20px;
        .iconContainer {
          width: 15px;
          .icon {
            path {
              fill: #7b828d;
            }
          }
        }
        .linkContainer {
          margin-left: 20px;
          .link {
            margin: 0;
            padding: 0;
            color: #ffffff;
            font-size: 15px;
          }
        }
      }
      .activeLinksContainer {
        font-family: ProximaNovaBold;
        text-decoration: none;
        display: flex;
        align-items: center;
        background-color: #ffffff;
        padding: 10px 20px;
        .iconContainer {
          width: 15px;
          .icon {
            path {
              fill: #ee405e;
            }
          }
        }
        .linkContainer {
          margin-left: 20px;
          .link {
            margin: 0;
            padding: 0;
            color: #ee405e;
            font-size: 15px;
          }
        }
      }
      .mainDiv {
        display: flex;
        padding-top: 10px;
        .iconContainer {
          width: 15px;
          margin-left: 20px;
          .icon {
            path {
              fill: #7b828d;
            }
          }
        }
        .ContentContainer {
          margin-left: 20px;
          color: white;

          p {
            color: #ffffff;
            font-size: 15px;
            width: 170px;
            font-weight: 400;
          }

          .link {
            i {
              margin-left: 5px;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .mainContainer {
    position: fixed;
    top: 0;
    // width: 250px;
    height: 100vh;
    background: #2d323e 0% 0% no-repeat;
    overflow-y: auto;
    overflow-x: hidden;
    opacity: 1;
    z-index: 1000;
    // display: none;
    // padding: 20px;
    transition: 700ms ease;
    .logoContainer {
      margin-top: 10px;
      width: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      img {
        width: 100px;
      }
      i {
        color: white;
      }
    }
    .menuContainer {
      padding-top: 20px;
      overflow-x: hidden;
      .linksContainer {
        font-family: ProximaNovaRegular;
        text-decoration: none;
        display: flex;
        align-items: center;
        // height: 40px;
        padding: 10px 20px;
        .iconContainer {
          width: 15px;
          .icon {
            path {
              fill: #7b828d;
            }
          }
        }
        .linkContainer {
          margin-left: 20px;
          .link {
            margin: 0;
            padding: 0;
            color: #ffffff;
            font-size: 15px;
          }
        }
      }
      .activeLinksContainer {
        font-family: ProximaNovaBold;
        text-decoration: none;
        display: flex;
        align-items: center;
        background-color: #ffffff;
        padding: 10px 20px;
        .iconContainer {
          width: 15px;
          .icon {
            path {
              fill: #ee405e;
            }
          }
        }
        .linkContainer {
          margin-left: 20px;
          .link {
            margin: 0;
            padding: 0;
            color: #ee405e;
            font-size: 15px;
          }
        }
      }
      .mainDiv {
        display: flex;
        padding-top: 10px;
        .iconContainer {
          width: 15px;
          margin-left: 20px;
          .icon {
            path {
              fill: #7b828d;
            }
          }
        }
        .ContentContainer {
          margin-left: 20px;
          color: white;

          p {
            color: #ffffff;
            font-size: 15px;
            width: 170px;
            font-weight: 400;
          }

          .link {
            i {
              margin-left: 5px;
            }
          }
        }
      }
    }
  }
}
