.container {
  a {
    width: 100%;
    text-decoration: none;
    color: unset;
  }
    header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 14px 18px 12px 18px;
        h4 {
            text-align: left;
            font: normal normal bold 20px/22px ProximaNovaBold;
            letter-spacing: 0px;
            color: #646464;
            opacity: 1;
            margin: 0px;
        }
        span {
            font-size: 20px;
        }
    }
.table {
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;
    tr:nth-child(even) {
        background-color: #FAFAFA;
    }
  .tableHeading {
      border-bottom: 1px solid #F3F3F3;
      box-shadow: 0px 4px 2px #E9E9E9;
      th {
        color: #000000;
        opacity: 1;
        margin: 0px;
        padding: 0px;
        padding: 12px 0px;
        text-align: center;
        font: normal normal normal 16px/18px ProximaNovaBold;
        letter-spacing: 0px;
        color: #323232;
      }
  }
  .tableData {
    border-bottom: 1px solid #F3F3F3;
      td {
        text-align: center;
        font: normal normal normal 13px/17px ProximaNovaBold;
        opacity: 0.8;
        margin: 0px;
        padding: 12px 0px;
        letter-spacing: 0px;
        color: #777777;
      }
  }
}
}

@media screen and (max-width: 768px) {
  .container {
    header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 14px 0px 10px 0px;
        h4 {
            text-align: left;
            font: normal normal bold 20px/22px ProximaNovaBold;
            letter-spacing: 0px;
            color: #646464;
            opacity: 1;
            margin: 0px;
        }
        span {
            font-size: 20px;
        }
    }
.table {
    border-collapse: collapse;
    margin: 0px 0px 0px -5px;
    padding: 0;
    width: 100%;
    table-layout: fixed;
    tr:nth-child(even) {
        background-color: #FAFAFA;
    }
  .tableHeading {
      border-bottom: 1px solid #F3F3F3;
      box-shadow: 0px 4px 2px #E9E9E9;
      th {
        color: #000000;
        opacity: 1;
        margin: 0px;
        padding: 0px;
        padding: 12px 0px;

        text-align: left;
        font: normal normal normal 13px/17px ProximaNovaBold;
        letter-spacing: 0px;
        color: #323232;
      }
  }
  .tableData {
    border-bottom: 1px solid #F3F3F3;
      td {
        text-align: left;
        font: normal normal normal 13px/17px ProximaNovaBold;
        opacity: 0.8;
        margin: 0px;
        padding: 12px 0px;
        letter-spacing: 0px;
        color: #777777;
      }
  }
}
}  
}