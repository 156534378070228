@media only screen and (min-width: 768px) {
    // all users container
    .container {
        padding: 35px 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .imgContainer {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 25px 0px 35px 0px;
            img {
                width: 220px;
            }
            .hideInput {
                opacity: 0;
                margin: -28px -200px 0px 0px;
            }
            ._files {
                font-size: 12px;
                margin-top: 5px;
            }
        }
        .form {
            display: flex;
            flex-direction: column;
            width: 75%;
            .field {
                margin: 15px 0px;
                display: flex;
                label {
                    width: 25%;
                    text-align: left;
                    font: normal normal normal 16px/20px ProximaNovaBold;
                    letter-spacing: 0px;
                    color: #343434;
                    opacity: 1;
                    margin-top: 10px;
                }
                input {
                    width: 75%;
                    background: #F2F4F5 0% 0% no-repeat padding-box;
                    border-radius: 4px;
                    opacity: 1;
                    border: none;
                    outline: none;
                    text-align: left;
                    font: normal normal normal 14px/1px ProximaNovaBold;
                    letter-spacing: 0px;
                    color: #707070;
                    padding: 16px 14px;
                }
        }
        .btnContainer {
            align-self: center;
            margin: 35px 0px 0px 0px;
        }
    }
    }
}

// for mobile devices

@media only screen and (max-width: 767px) {
    // all users container
    .container {
        padding: 35px 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .imgContainer {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 0px 0px 35px 0px;
            overflow: hidden;
            img {
                width: 180px;
            }
            .hideInput {
                opacity: 0;
                margin: -35px -200px 0px 0px;
            }
        }
        .form {
            display: flex;
            flex-direction: column;
            width: 100%;
            .field {
                margin: 12px 0px;
                display: flex;
                flex-direction: column;
                label {
                    width: 100%;
                    text-align: left;
                    font: normal normal normal 16px/20px ProximaNovaBold;
                    letter-spacing: 0px;
                    color: #343434;
                    opacity: 1;
                    margin-bottom: 10px
                }
                input {
                    width: 100%;
                    max-width: 600px;
                    background: #F2F4F5 0% 0% no-repeat padding-box;
                    border-radius: 4px;
                    opacity: 1;
                    border: none;
                    outline: none;
                    text-align: left;
                    font: normal normal normal 14px/1px ProximaNovaBold;
                    letter-spacing: 0px;
                    color: #707070;
                    padding: 16px 14px;
                }
        }
        .btnContainer {
            align-self: center;
            margin: 20px 0px 0px 0px;
        }
    }
    }
}