@media screen and (min-width: 768px) {
  .subSection {
    display: flex;
    justify-content: space-between;
    .leftSection {
      width: 68%;
      display: flex;
      flex-direction: column;
      .container {
        padding: 20px 0px;
        margin-bottom: 10px;
        background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
        background: #ffffff 0% 0% no-repeat padding-box;
        border-radius: 10px;
        opacity: 1;
        display: flex;
        flex-direction: column;
        header {
          width: 100%;
          display: flex;
          align-items: center;
          padding: 5px 20px;
          h4 {
            margin-right: 10px;
          }
          .badge {
            padding: 4px 12px;
            background-color: cornflowerblue;
          }
        }
        .wrapper {
          display: flex;
          .imgContainer {
            width: 40%;
            display: flex;
            place-content: center;
            img {
              width: 90%;
            }
          }
          .contentContainer {
            width: 60%;
            .iconsContainer {
              width: 55%;
              display: flex;
              align-items: center;
              flex-wrap: wrap;
              font-size: 14px;
              color: grey;
              margin-bottom: 15px;
              i {
                font-size: 14px;
                margin: 0px 7px;
              }
            }
            .features {
              margin: 0px 0px 15px 7px;
              h6 {
                margin: 5px 0px;
              }
              ul {
                // list-style-image: url('../../../Assets/booking/checkmark.svg');
                list-style: none;
                color: grey;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                margin: 0px;
                padding: 0px;
                li {
                  width: 50%;
                }
              }
            }
            .reviews {
              display: flex;
              align-items: center;
              margin: 0px 0px 0px 7px;
              p {
                margin: 0px;
                font-weight: bold;
                font-size: 14px;
                margin-right: 3px;
              }
              p:nth-child(3) {
                color: rgb(88, 177, 0);
              }
              span {
                background: rgb(88, 177, 0);
                color: white;
                font-weight: bold;
                font-size: 12px;
                padding: 3px;
                margin-right: 5px;
              }
            }
          }
        }
      }
      .container:nth-child(2) {
        padding: 20px 20px;
        header {
          padding: 0px;
          margin-bottom: 10px;
        }
      }
    }
    .rightSection {
      width: 30%;
      display: flex;
      flex-direction: column;
      .container {
        margin-bottom: 10px;
        background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
        background: #ffffff 0% 0% no-repeat padding-box;
        border-radius: 10px;
        opacity: 1;
        padding: 15px;
        .steps {
          .step {
            h6 {
              margin: 0px 0px 5px 0px;
            }
            p {
              font-size: 14px;
              color: grey;
            }
            p:nth-child(1) {
              margin: 0px;
            }
            p:nth-child(2) {
              margin: 0px;
            }
          }
        }
        .button {
          width: 100%;
          display: flex;
          place-content: center;
          button {
            background: none;
            border: none;
            color: red;
            font-weight: bold;
            font-size: 14px;
          }
        }
      }

      .container:nth-child(2) {
        .priceContainer {
          display: flex;
          align-items: center;
          justify-content: space-between;
          p:nth-child(1) {
            color: grey;
            margin: 0px;
            font-size: 14px;
          }
          p:nth-child(2) {
            font-weight: bold;
            margin: 0px;
          }
        }
        span {
          p {
            font-size: 12px;
            color: grey;
            margin: 0px;
            line-height: 18px;
          }
        }
        .priceContainer:nth-child(4) {
          margin-top: 10px;
        }
        hr {
          margin: 8px;
        }
        .totalPrice {
          display: flex;
          align-items: center;
          justify-content: space-between;
          p:nth-child(1) {
            font-weight: bold;
          }
          p:nth-child(2) {
            font-weight: bold;
            font-size: 26px;
          }
        }
        button {
          border: none;
          background: rgb(88, 177, 0);
          width: 100%;
          padding: 10px 0px;
          border-radius: 5px;
          color: #ffffff;
          font-size: 18px;
          margin-bottom: 10px;
        }
        .check {
          display: flex;
          align-items: center;
          input {
            margin-right: 5px;
          }
          p {
            font-size: 12px;
            margin: 0px;
          }
        }
        .status {
          display: flex;
          place-content: center;
          padding: 10px 0px;
          p {
            color: red;
            font-size: 14px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .subSection {
    display: flex;
    flex-direction: column;
    .leftSection {
      width: 100%;
      display: flex;
      flex-direction: column;
      .container {
        padding: 20px 0px;
        margin-bottom: 10px;
        background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
        background: #ffffff 0% 0% no-repeat padding-box;
        border-radius: 10px;
        opacity: 1;
        display: flex;
        flex-direction: column;
        header {
          width: 100%;
          display: flex;
          align-items: center;
          padding: 5px 20px;
          h4 {
            margin-right: 10px;
            font-size: 20px;
          }
          .badge {
            padding: 4px 12px;
            background-color: cornflowerblue;
          }
        }
        .wrapper {
          display: flex;
          flex-direction: column;
          .imgContainer {
            margin: 15px 0px;
            width: 100%;
            display: flex;
            place-content: center;
            img {
              width: 90%;
            }
          }
          .contentContainer {
            width: 90%;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 15px 0px;
            .iconsContainer {
              width: 80%;
              display: flex;
              align-items: center;
              justify-content: center;
              flex-wrap: wrap;
              font-size: 14px;
              color: grey;
              margin-bottom: 15px;
              i {
                font-size: 14px;
                margin: 0px 7px;
              }
            }
            .features {
              margin: 0px 0px 15px 7px;
              display: flex;
              flex-direction: column;
              align-items: center;
              h6 {
                margin: 5px 0px;
              }
              ul {
                list-style: none;
                color: grey;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                margin: 0px;
                padding: 0px;
                li {
                  // width: 50%;
                  margin: 0px 10px;
                }
              }
            }
            .reviews {
              display: flex;
              align-items: center;
              margin: 0px 0px 0px 7px;
              p {
                margin: 0px;
                font-weight: bold;
                font-size: 14px;
                margin-right: 3px;
              }
              p:nth-child(3) {
                color: rgb(88, 177, 0);
              }
              span {
                background: rgb(88, 177, 0);
                color: white;
                font-weight: bold;
                font-size: 12px;
                padding: 3px;
                margin-right: 5px;
              }
            }
          }
        }
      }
      .container:nth-child(2) {
        padding: 20px 20px;
        header {
          padding: 0px;
          margin-bottom: 10px;
        }
      }
    }
    .rightSection {
      width: 100%;
      display: flex;
      flex-direction: column;
      .container {
        margin-bottom: 10px;
        background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
        background: #ffffff 0% 0% no-repeat padding-box;
        border-radius: 10px;
        opacity: 1;
        padding: 15px;
        .steps {
          .step {
            h6 {
              margin: 0px 0px 5px 0px;
            }
            p {
              font-size: 14px;
              color: grey;
              margin: 8px 0px;
            }
          }
        }
        .button {
          width: 100%;
          display: flex;
          place-content: center;
          button {
            background: none;
            border: none;
            color: red;
            font-weight: bold;
            font-size: 14px;
          }
        }
      }

      .container:nth-child(2) {
        h6 {
          font-size: 20px;
          text-align: center;
        }
        .priceContainer {
          display: flex;
          align-items: center;
          justify-content: space-between;
          p:nth-child(1) {
            color: grey;
            margin: 0px;
            font-size: 14px;
          }
          p:nth-child(2) {
            font-weight: bold;
            margin: 0px;
          }
        }
        span {
          p {
            font-size: 12px;
            color: grey;
            margin: 0px;
            line-height: 18px;
          }
        }
        .priceContainer:nth-child(4) {
          margin-top: 10px;
        }
        hr {
          margin: 8px;
        }
        .totalPrice {
          display: flex;
          justify-content: center;
          h3 {
            text-align: center;
          }
        }
        button {
          border: none;
          background: rgb(88, 177, 0);
          width: 100%;
          padding: 10px 0px;
          border-radius: 5px;
          color: #ffffff;
          font-size: 18px;
          margin: 10px 0px;
        }
        .check {
          display: flex;
          align-items: center;
          input {
            margin-right: 5px;
          }
          p {
            font-size: 12px;
            margin: 0px;
          }
        }
        .status {
          display: flex;
          place-content: center;
          padding: 10px 0px;
          p {
            color: red;
            font-size: 14px;
          }
        }
      }
    }
  }
}
